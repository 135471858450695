import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/logo.png'


const _hoisted_1 = { id: "global-header" }

import { routes } from "@/router/routes";
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
// 路由

export default /*@__PURE__*/_defineComponent({
  __name: 'GlobalHeader',
  setup(__props) {

const router = useRouter();
const visibleRoutes = computed(() => {
  return routes.filter((item) => {
    //是否在菜单中可见
    return !item.meta?.hideInMenu;
  });
});
const selectedKeys = ref(["/"]); //默认是主页
// 路由跳转时更新选中菜单项
router.afterEach((to) => {
  selectedKeys.value = [to.path];
});
const doMenuClick = (key: string) => {
  router.push({ path: key });
};

return (_ctx: any,_cache: any) => {
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_row, {
      id: "globalHeader",
      wrap: false,
      align: "center",
      style: {"margin-bottom":"16px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { flex: "auto" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_menu, {
              "selected-keys": selectedKeys.value,
              mode: "horizontal",
              onMenuItemClick: doMenuClick
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_menu_item, {
                  key: "0",
                  style: { padding: 0, marginRight: '38px' },
                  disabled: ""
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createElementVNode("div", { class: "title-bar" }, [
                      _createElementVNode("img", {
                        alt: "team-logo",
                        class: "team-logo",
                        width: "40rem",
                        height: "40rem",
                        src: _imports_0
                      }),
                      _createElementVNode("div", { class: "title" }, "*C* Team")
                    ], -1)
                  ])),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleRoutes.value, (item) => {
                  return (_openBlock(), _createBlock(_component_a_menu_item, {
                    key: item.path
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.name), 1)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }, 8, ["selected-keys"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})